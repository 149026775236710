import React from 'react'
// import {Link} from 'gatsby'
import shape1 from '../../assets/images/services/shape-shield2.png'
import shape2 from '../../assets/images/services/service-g4.png'
import shape3 from '../../assets/images/services/shape-shield.png'
import ReactWOW from 'react-wow'
// import Bubbles2 from './Bubbles2';

const PageBannerCompliance = ({pageTitle, homePageUrl, homePageText, screenShot, animation, activePageText}) => {
    return (
        <div className="services-page-title-area compliance-hero">
         {/* <Bubbles2 />  */}
          {/* <div id="jellyfish1" /> */}
           {/* <div id="jellyfish2" /> */}
            {/* <div id="jellyfish" />  */}
            <div className="container">
                <div className="row align-items-center">
                    <div className="page-title-content col-lg-6 col-md-12">
                        <h2 style={{color: 'white'}}>{pageTitle}</h2>
                        <ul>
                            <li>
                                <h3 style={{color: 'white'}}>
                                    {homePageText}
                                </h3>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW>
                            <div className="banner-wrapper-image  {animation}">
                                <img src={screenShot} className="half-screen" alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
           
            <div className="shape-img2" style={{maxWidth: 50}}>
                <img src={shape1} alt="banner" />
            </div>
             <div className="shape-img3" style={{maxWidth: 80}}>
                <img src={shape2} alt="banner" />
            </div>
             <div className="shape-img4" style={{maxWidth: 100, top:'70%', right:'4%'}}>
                <img src={shape3} alt="banner" />
            </div>
            <div className="shape-img5" style={{maxWidth: 40}}>
                <img src={shape1} alt="banner" />
            </div>
            <div className="shape-img6" style={{maxWidth: 80, top: '65%', right: '46%'}}>
                <img src={shape2} alt="banner" />
            </div>
            <div className="shape-img7" style={{maxWidth: 50}}>
                <img src={shape3} alt="banner" />
            </div> 
  
        </div>
    )
}

export default PageBannerCompliance;