import React from 'react'
import service4 from '../../assets/images/compliance/compliance-main3.png'
import logo1 from '../../assets/images/compliance/logo-hitrust.png'
import logo2 from '../../assets/images/compliance/logo-aicpa.png'
import logo3 from '../../assets/images/compliance/logo-fedramp.png'
import logo4 from '../../assets/images/compliance/logo-hippa.png'
import logo5 from '../../assets/images/compliance/logo-pci.png'
import logo6 from '../../assets/images/compliance/logo-cmmc.png'
// import service6 from '../assets/images/services/service6.png'

const ComplianceDetails = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
              
                            
                    <div className="overview-image left">
                            <span className="sub-title">ekko</span>
                            <h2>Implementation, Assessment, and Compliance</h2>
                            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                        <div className="image">
                            <img src={service4} alt="features" />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            
                                <ul className="features-list">
                                    <li className="compliance-node">
                                        <img src={logo1} style={{maxWidth: 100}} alt="banner" />
                          
                                        <ul>
                                            <li>HITRUST program scoping and implementation (MYCSF)</li>
                                            <li>Policy and procedure documentation</li>
                                            <li>HITRUST control remediation advisory services and program management</li>
                                        </ul>
                                    </li>
                                  
                                    <li className="compliance-node">
                                        <img src={logo2} style={{maxWidth: 100}} alt="banner" />
                          
                                        <ul>
                                            <li>SSAE18 SOC 2 control design</li>
                                            <li>SOC 2 management report attestation support, scoping and documentation services</li>
                                            <li>SOC 2 Type 1 &amp; 2 program management, remediation services and implementation</li>
                                        </ul>
                                    </li>
                                  
                                    <li className="compliance-node">
                                        <img src={logo3} style={{maxWidth: 100}} alt="banner" />
                          
                                        <ul>
                                            <li>SSAE18 SOC 2 control design</li>
                                            <li>SOC 2 management report attestation support, scoping and documentation services</li>
                                            <li>SOC 2 Type 1  2 program management, remediation services and implementation</li>
                                        </ul>
                                    </li>
                                  
                                  <li className="compliance-node">
                                        <img src={logo4} style={{maxWidth: 100}} alt="banner" />
                          
                                        <ul>
                                            <li>SSAE18 SOC 2 control design</li>
                                            <li>SOC 2 management report attestation support, scoping and documentation services</li>
                                            <li>SOC 2 Type 1 & 2 program management, remediation services and implementation</li>
                                        </ul>
                                    </li>
                                  
                                  <li className="compliance-node">
                                        <img src={logo5} style={{maxWidth: 100}} alt="banner" />
                          
                                        <ul>
                                            <li>SSAE18 SOC 2 control design</li>
                                            <li>SOC 2 management report attestation support, scoping and documentation services</li>
                                            <li>SOC 2 Type 1 & 2 program management, remediation services and implementation</li>
                                        </ul>
                                    </li>
                                  <li className="compliance-node">
                                        <img src={logo6} style={{maxWidth: 100}} alt="banner" />
                          
                                        <ul>
                                            <li>SSAE18 SOC 2 control design</li>
                                            <li>SOC 2 management report attestation support, scoping and documentation services</li>
                                            <li>SOC 2 Type 1 & 2 program management, remediation services and implementation</li>
                                        </ul>
                                    </li>
                                  

                                </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

              

              
            </div>
        </section>
    )
}

export default ComplianceDetails